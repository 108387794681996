import React, { useEffect, useState } from 'react'
import { useEmblaCarousel } from 'embla-carousel-react'
import styles from './Carousel.module.scss'
import Arrow from '../icons/BackArrow'
import posed from 'react-pose'

const Container = posed.div({
  enter: {
    scale: 1,
  },
  exit: {
    scale: 0,
  },
})

export default function Carousel({ children, startingIndex }) {
  const [EmblaCarouselReact, embla] = useEmblaCarousel({
    loop: true,
    startIndex: startingIndex,
  })

  const [current, setCurrent] = useState(startingIndex + 1)

  useEffect(() => {
    if (embla) {
      embla.reInit({ loop: true, startIndex: startingIndex })
      embla.on('select', () => {
        setCurrent(embla.selectedScrollSnap() + 1)
      })
    }
  }, [embla, startingIndex])

  return (
    <>
      <Container className={styles.embla}>
        <EmblaCarouselReact className={styles.viewport}>
          <div className={styles.container}>
            {children.map((Child, index) => {
              return (
                <div key={index} className={styles.slide}>
                  <div className={styles.slideInner}>{Child}</div>
                </div>
              )
            })}
          </div>
        </EmblaCarouselReact>
        <button
          onClick={() => embla.scrollPrev()}
          className={styles.backbutton}
        >
          <div className={styles.arrow}>
            <Arrow />
          </div>
        </button>
        <button
          onClick={() => embla.scrollNext()}
          className={styles.nextbutton}
        >
          <div className={styles.arrow}>
            <Arrow />
          </div>
        </button>
        <p className={styles.counter}>{`${current} / ${children.length}`}</p>
      </Container>
    </>
  )
}
