// modules
import React from 'react'
// styles
import styles from './MasonryColumns.module.scss'

// definitions
function MasonryColumns({ children }) {
  if (children) {
    return (
      <ul className={styles.Masonry}>
        {React.Children.map(children, child => {
          return child ? (
            <li>
              <div className={styles.item}>{child}</div>
            </li>
          ) : null
        })}
      </ul>
    )
  } else {
    return null
  }
}

// exports
export default MasonryColumns
