export const animations = {
  // Backdrop
  Backdrop: {
    enter: {
      opacity: 1,
      transition: { duration: 200 },
    },
    exit: {
      opacity: 0,
      transition: { duration: 200 },
    },
  },
  // ImageWrapper
  LightboxContent: {
    enter: {
      scale: 1,
    },
    exit: {
      scale: 0,
    },
  },
}
