// modules
import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
// styles
import styles from './index.module.scss'
// components
import MasonryColumns from '../components/shared/MasonryColumns/MasonryColumns'
import SEO from '../components/shared/SEO/SEO'
// import Lightbox from '../components/shared/Lightbox/Lightbox'
// import Featured from '../components/Featured/Featured'
import Carousel from '../components/shared/Carousel/Carousel'
import Lightbox from '../components/shared/Lightbox/Lightbox'

// definitions
function IndexPage({ data }) {
  const pageTitle = 'Paintings'
  const paintings = data.allMarkdownRemark.edges
  const [selectedPaintIndex, setSelectedPaintIndex] = useState(0)
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false)

  const handlePaintSelect = index => {
    setSelectedPaintIndex(index)
    setLightboxIsOpen(true)
  }

  const handleCloseLightbox = () => {
    setLightboxIsOpen(false)
  }

  return (
    <>
      <SEO title={pageTitle} />
      {/* <h1>{pageTitle}</h1> */}
      <Lightbox handleClose={handleCloseLightbox} isOpen={lightboxIsOpen}>
        <Carousel startingIndex={selectedPaintIndex}>
          {paintings.map(painting => {
            return (
              <div key={painting.node.id} className={styles.imageWrapper}>
                <Img
                  fluid={painting.node.frontmatter.image.childImageSharp.fluid}
                  className={styles.imageWrapper}
                  imgStyle={{ objectFit: 'contain' }}
                />
                <h3 className={styles.paintingLegend}>
                  {painting.node.frontmatter.title}
                </h3>
              </div>
            )
          })}
        </Carousel>
      </Lightbox>

      {/* <Featured /> */}
      {paintings.length ? (
        <MasonryColumns>
          {paintings.map((painting, index) => {
            const {
              node: { id, frontmatter },
            } = painting
            const title = frontmatter.title
            const image = frontmatter.image
              ? frontmatter.image.childImageSharp.fluid
              : null

            if (image && title) {
              return (
                <div
                  className={styles.paintingFrame}
                  onClick={() => handlePaintSelect(index)}
                  role="button"
                  tabIndex={0}
                  key={id}
                >
                  <Img
                    fluid={image}
                    title={title}
                    alt={title}
                    className={styles.img}
                  />
                </div>
              )
            } else {
              return null
            }
          })}
        </MasonryColumns>
      ) : null}
    </>
  )
}

// exports
export default IndexPage

// query
export const indexPageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "./content/paintings/" } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
