import React from 'react'
import styles from './Lightbox.module.scss'
import CrossIcon from '../icons/CrossIcon'
import posed, { PoseGroup } from 'react-pose'
import { animations } from './LightboxAnimations'

const Backdrop = posed.div(animations.Backdrop)

export default function Lightbox({ children, handleClose, isOpen }) {
  return (
    <PoseGroup flipMove={false}>
      {isOpen ? (
        <Backdrop className={styles.lightbox} key="lightboxBackdrop">
          <div
            className={styles.crossIcon}
            onClick={() => handleClose()}
            role="button"
            tabIndex="0"
          >
            <CrossIcon />
          </div>
          {children}
        </Backdrop>
      ) : null}
    </PoseGroup>
  )
}
